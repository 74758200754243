import React from "react";
const subheadingsData = {
    1: [
        {
            title: "About Me",
            content: (
                <>
                    <p> 
    
                        A passionate sophomore studying Computer Science at Arizona State University, my fascination with Programming & Engineering began in middle school when I discovered the world of communicating with computers. Since then, I've explored various engineering disciplines beyond coding. This website, which I programmed, serves as a beacon, nurturing my curiosity and showcasing my vast skills and field-related experiences, propelling me further every day
                        
                    </p>
                
                </>
            )
        }
    ],
    2: [
        {
            title: "Arizona State Univeristy ",
            content: (
                <>
                    <p>
        
                         Computer Science - Engineering  
                        </p>

                        <p> (B.S) Expected May 2026</p>


                        <p> <u>Ongoing Courses </u> - </p>
                       <p> Principles of Programming, Object-Oriented Programming, Computer Architecture, Digital Systems Design, and  Data Structures 

                        </p>
                        

                </>
            )
        }
    ],
    3: [
        {
            title: "Computer Systems Technician ",
            content: (
                <>
                   <p> 
                        AT (CS & S) , TEMPE AZ. 
                        </p>

                        <p> 
Currently, I am honing my expertise as a part-time Computer Systems Technician at CS&S Computers, the Valley's premier IT service provider. In this role, my responsibilities entail maintaining, diagnosing, and repairing computer equipment and server infrastructure. I operate under certified partnerships with industry leaders such as Lenovo, Hewlett Packard, Toshiba, and more, ensuring top-tier technical hardware and software proficiency. A detailed description of my role is available on my resume!</p>
                        

                    
                </>
            ),
        },
    ],
};

export default subheadingsData;
