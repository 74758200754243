import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import DOMPurify from 'dompurify';

export default function ContactMenu() {


  const initialState = {
    name: '',
    email: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required.';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = 'Invalid email format.';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required.';
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validateErrors = validateForm();
    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      return;
    }
    setIsLoading(true);

    const sanitizedData = {
      name: 'Name: ' + DOMPurify.sanitize(formData.name),
      email: 'Email: ' + DOMPurify.sanitize(formData.email),
      message: 'Message: ' + DOMPurify.sanitize(formData.message),
    };

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceID, templateID, sanitizedData, userID)
      .then((response) => {
        console.log('Email has been sent successfully!', response.text);
        setFormData(initialState);
        setErrors({});
        setIsSent(true);
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="contact-menu">
      {!isSent && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              className={errors.name ? 'input-error' : ''}
              disabled={isLoading}
              autoComplete='name'
            />
                {errors.name && <span className="error-message">{errors.name}</span>}
                </div>
                <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? 'input-error' : ''}
              disabled={isLoading}
              autoComplete='email'
            />
                {errors.email && <span className="error-message">{errors.email}</span>}
                </div>
                <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              autoComplete='off'
              id="message"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              className={errors.message ? 'input-error' : ''}
              disabled={isLoading}
            ></textarea>
                {errors.message && <span className="error-message">{errors.message}</span>}
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? "SENDING..." : "SUBMIT"}
                    </button>
                </form>
      )}
      {isSent && (
        <div className="sucesss-message">
            <p> SUCCESS!</p>
            <p> Youe Message Has Been Sent Successfully!</p>
            <p> You Can Safely Leave This Page.</p>
        </div>
      )}
    </div>
  );
}
