const skills = {
    1: [ 
        {

        title: "HTML",
        level: 4,
    },
    {

        title: "CSS",
        level: 4,
    },
    {

        title: "Python",
        level: 4,
    },
    {

        title: "React JS",
        level: 4,
    },
    {

        title: "C++",
        level: 4,
    },
    {

        title: "JavaScript",
        level: 4,
    },
    {

        title: "Java",
        level: 4,
    },
    {

        title: "TypeScript",
        level: 4,
    },
    
],
2: [ 
    {

    title: "Node.js",
    level: 4,
},
{

    title: "Python",
    level: 4,
},
{

    title: "Java",
    level: 4,
},
{

    title: "C",
    level: 4,
},
{

    title: "Git",
    level: 4,
},
{

    title: "JavaScript",
    level: 4,
},
{

    title: "P5.js",
    level: 4,
},
],


}
export default skills;