import React from "react";
import projectOne from "../assets/project-1.png";
import projectTwo from "../assets/project-2.png";
import projectThree from "../assets/project-3.png";

export const projects = {
    1: {
        title: "The Stoic Post ",
        Image: projectOne,
        description: (
            <>

            <p>     Engineered and founded 'The Stoic Post,' a daily newsletter platform built as a full-stack website using React.js. Implemented UI enhancements and integrated email and data APIs to ensure top-tier user security and experience. Solely responsible for end-to-end project execution, including design, configuration, and deployment. </p>
            
            </>
        ),
        github: "https://github.com",
        resume: "https://docs.google.com/document/d/1s1ARupqrmxzBiNplcw114XDboEanqDWQEccmALB_y1I/edit?usp=sharing"
    },
    2: {
        title: "MotorSkill App ",
        Image: projectTwo,
        description: (
            <>

            <p>
                
Developed FMS application using JavaScript, incorporating mini-interactive games to enhance toddlers' motor skills. Monitored and recorded user growth, with 25% showing improved abilities. Featured six games, including "Time-Touch," a game generating random ball movements on a 500x500 plane, designed for toddler interaction. A 7.5-second delay mechanism concluded the game, recording scores. "Time-Touch" contributed to 70% of the project's analytical outcomes.
                 </p>
            
            </>
        ),
        github: "https://github.com",
        resume: "https://google.com"
    },
    3: {
        title: " 4-bit Cpu Circuit ",
        Image: projectThree,
        description: (
            <>

            <p> 
Designed and implemented a 4-bit CPU with digital design software and GTKWave, applying Boolean logic and arithmetic optimizations to increase computational efficiency by 15%. Conducted extensive simulations, improving performance and error handling by 20%. Showcased expertise in digital circuit design and signal processing, contributing to a notable project at the nexus of mathematics and computer science. </p>
            
            </>
        ),
        github: "https://github.com",
        resume: "https://google.com"
    }
}
export default projects;