import React from "react";
import "../styles/playerStats.css";

const PlayerStats = () => {
    return (
        <div id="playerStats">
          <h1>  Aziz  Ibrahim  </h1>
          <div id="playerStats_lines">
                <span
                id="playerStats__lines__thick"></span>
                <span
                id="playerStats__lines__thin"></span>
        </div>
        <h2> II YR'  COMPSCI MJR  </h2>
        </div>
    );
};
export default PlayerStats;